import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { AuthenticationService } from 'src/app/auth/service';
import { ProxyGateway } from '@parameters/http/api-proxy-gateway';
import { environment } from '@environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private readonly _commonService: CommonService
  ) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this._authenticationService.authToken;
    const isApiUrl = request.url.startsWith(ProxyGateway.loadBaseApiUrl());

    const isPaymentUrl = request?.url?.startsWith(
      environment.paymentApiBaseUrl
    );

    const isIAMUrl = request?.url?.startsWith(environment.iamApiBaseUrl);

    if (isLoggedIn && (isApiUrl || isPaymentUrl || isIAMUrl)) {
      const httpHeaders = {
        'cache-control': 'no-cache',
        // 'ngrok-skip-browser-warning': 'true',
        Authorization: `Bearer ${this._authenticationService.authToken}`,
        ...(request.body instanceof FormData ||
        request.headers.has('Content-Type')
          ? undefined
          : {
              'Content-Type': 'application/json',
            }),
      };

      if (isPaymentUrl) {
        if (!this._commonService.paymentAccessToken) return of();

        Object.assign(httpHeaders, {
          'Gateway-Authorization': `Bearer ${this._commonService.paymentAccessToken}`,
        });
      }

      request = request.clone({
        setHeaders: httpHeaders,
      });

      return next.handle(request);
    }

    return next.handle(request);
  }
}
